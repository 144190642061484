@import '~/ui/assets/styles/colors.module.scss';

.accessDenied {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 20px;
}

.image {
  width: 300px;
}

.title {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 300;
}

.subTitle {
  margin: 0 0 15px;
  font-size: 0.8rem;
  color: $color-grey-4;
}

.link {
  text-decoration: none;
}
