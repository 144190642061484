@import '~/ui/assets/styles/colors.module.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.itemsWrapper {
  border-radius: 20px !important;
  border: 1px solid $color-grey-4;
}

.item {
  background-color: white;
  padding: 15px;
  border-bottom: 1px solid $color-grey-4;
  font-weight: 300;
}

.itemHeader {
  display: inline-block;
  width: 100px;
}

.itemDetails {
  color: grey;
  margin-left: 25px;
}

.item:first-of-type {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.item:last-of-type {
  border: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.buttonContainer {
  margin-top: 24px;
  margin-bottom: 50px;

  & > * {
    margin-right: 15px !important;
  }

  & > span {
    color: $color-grey-4;
  }
}

.uploaderWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.error {
  color: $color-red-3;
}

.row {
  padding-top: 20px;
}

.roleSelect {
  padding: 20px;
  background-color: white;

  & .header {
    margin-bottom: 0;
  }
}

.changePasswordForm {
  width: 41.66%;
  padding: 12px;
}

.changePassword {
  box-shadow: none !important;
  padding: 20px;
  margin-top: 0px !important;
  width: 100%;

  & h2 {
    font-size: 1.17rem;
  }

  & div:last-child {
    margin-bottom: 0;
  }

  & > div {
    margin-top: 20px;
  }
}
